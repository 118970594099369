<template>
  <v-row justify="center">
    <v-dialog v-model="exportDialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Exporter la sélection courante
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >Exporter la sélection courante - choix des champs</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="checkedAll"
                  label="Tout cocher"
                  @change="checkAll"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-form ref="formExport">
              <v-row>
                <v-col cols="12">
                  <div class="checkboxes-wrapper">
                    <div
                      v-for="field in headers"
                      :key="field.value"
                      class="checkboxes-item"
                    >
                      <v-checkbox
                        v-model="selectedFields"
                        :label="field.text"
                        :value="field.value"
                        @change="changeSelectedField"
                      ></v-checkbox>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="checkboxes-wrapper">
                    <div class="checkboxes-item">
                      <v-checkbox
                        v-model="archive"
                        label="Exporter en tant que archive"
                      ></v-checkbox>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="archive">
                <v-col cols="12">
                  <v-text-field
                    v-model="limit"
                    label="Nombre de contacts par fichiers"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="exportDialog = false">
            Fermer
          </v-btn>
          <v-btn color="blue darken-1" text @click="csvExport">
            Exporter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "ExportForm",
  data: () => ({
    formExport: {},
    exportDialog: false,
    selectedFields: [],
    checkedAll: false,
    archive: false,
    limit: 200,
  }),
  props: ["headers"],
  methods: {
    checkAll() {
      this.selectedFields = this.checkedAll
        ? this.headers.map((header) => header.value)
        : [];
    },
    changeSelectedField() {
      this.checkedAll =
        this.selectedFields.length === this.headers.length ? true : false;
    },

    csvExport() {
      this.$store.dispatch("exportContacts", {
        fields: this.selectedFields,
        archive: this.archive,
        limit: this.limit,
      });
      this.exportDialog = false;
    },
  },
};
</script>
