import Vue from "vue";
import Vuex from "vuex";
import storageHelper from "../utils/storage.js";
import requestHelper from "../utils/request.js";
import config from "../config.js";
import router from "../router/index.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    contacts: [],
    pager: {
      currentPage: 34,
      itemsPerPage: 50,
      totalItems: 0,
    },
    sorts: [],
    filters: [],
    notification: {
      pending: false,
      success: false,
      error: undefined,
      message: "",
    },
  },
  mutations: {
    SET_USER: (state, { user }) => {
      state.user = user;
    },
    UNSET_USER: (state) => {
      state.user = {};
    },
    SET_CONTACTS: (state, { contacts }) => {
      state.contacts = contacts;
    },
    SET_PAGER: (state, { currentPage, itemsPerPage, totalItems }) => {
      state.pager.currentPage = currentPage;
      state.pager.itemsPerPage = itemsPerPage;
      state.pager.totalItems = totalItems;
    },
    ADD_SORT: (state, { sort }) => {
      if (sort) {
        const sortArray = sort.split(":");
        const index = state.sorts.findIndex((item) => {
          const a = item.split(":");
          return a[0] === sortArray[0];
        });
        if (index > -1) {
          state.sorts.splice(index, 1);
        }

        state.sorts.push(sort);
      }
    },
    REMOVE_SORT: (state, { sort }) => {
      if (sort) {
        const sortArray = sort.split(":");
        const index = state.sorts.findIndex((item) => {
          const a = item.split(":");
          return a[0] === sortArray[0];
        });
        if (index > -1) {
          state.sorts.splice(index, 1);
        }
      }
    },
    ADD_FILTER: (state, { filter }) => {
      if (filter) {
        const filterArray = filter.split(":");
        const index = state.filters.findIndex((item) => {
          const a = item.split(":");
          return a[0] === filterArray[0];
        });
        if (index > -1) {
          state.filters.splice(index, 1);
        }
        state.filters.push(filter);
      }
    },
    REMOVE_FILTER: (state, { filter }) => {
      if (filter) {
        const sortArray = filter.split(":");
        const index = state.filters.findIndex((item) => {
          const a = item.split(":");
          return a[0] === sortArray[0];
        });
        if (index > -1) {
          state.filters.splice(index, 1);
        }
      }
    },
    DELETE_PENDING: (state) => {
      state.notification.pending = true;
      state.notification.success = false;
      state.notification.error = undefined;
    },
    DELETE_SUCCESS: (state) => {
      state.notification.pending = false;
      state.notification.success = true;
      state.notification.error = undefined;
    },
    DELETE_ERROR: (state, { error }) => {
      state.notification.pending = false;
      state.notification.success = false;
      state.notification.error = error;
    },
    EDIT_PENDING: (state) => {
      state.notification.pending = true;
      state.notification.success = false;
      state.notification.error = undefined;
    },
    EDIT_SUCCESS: (state) => {
      state.notification.pending = false;
      state.notification.success = true;
      state.notification.error = undefined;
    },
    EDIT_ERROR: (state, { error }) => {
      state.notification.pending = false;
      state.notification.success = false;
      state.notification.error = error;
    },
    EXPORT_SUCCESS: (state) => {
      state.notification.pending = false;
      state.notification.success = true;
      state.notification.error = undefined;
    },
    EXPORT_ERROR: (state, { error }) => {
      state.notification.pending = false;
      state.notification.success = false;
      state.notification.error = error;
    },
    SET_NOTIFICATION_MESSAGE: (state, { message }) => {
      state.notification.message = message;
    },
    SET_NOTIFICATION_PENDING: (state) => {
      state.notification.pending = true;
    },
  },

  getters: {
    user: (state) => {
      return state.user;
    },
  },
  computed: {
    user: () => {
      return this.$store.getters.user;
    },
  },
  actions: {
    login: async ({ dispatch }, { email, password }) => {
      const url = config.API_END_POINT + "auth/login";
      return requestHelper
        .request({
          url,
          method: "post",
          body: { email: email, password: password },
        })
        .then((response) => {
          const { user } = response;
          if (user) {
            dispatch("setUser", user);
            router.push({ name: "Home" });
          }
          return response;
        });
    },
    isLoggedIn: async ({ state, commit }) => {
      const { user } = state;

      if (!user.token && storageHelper.getUser()) {
        // resync
        commit("SET_USER", { user: storageHelper.getUser() });
      }

      return user.token || storageHelper.getUser() ? true : false;
    },
    setUser: ({ commit }, user) => {
      storageHelper.setUser(user);
      commit("SET_USER", { user });
    },
    setContacts: ({ commit }, contacts) => {
      commit("SET_CONTACTS", { contacts });
    },
    logout: ({ commit }) => {
      storageHelper.unsetUser();
      commit("UNSET_USER");
    },
    removeSort: ({ commit }, sort) => {
      commit("REMOVE_SORT", { sort });
    },
    removeFilter: ({ commit }, filter) => {
      commit("REMOVE_FILTER", { filter });
    },
    exportContacts: ({ state, commit }, { fields, archive, limit }) => {
      commit("SET_NOTIFICATION_PENDING");
      const { token } = state.user;

      const sortQuery =
        Object.keys(state.sorts).length > 0
          ? "sorts=" + state.sorts.join(",")
          : "";

      const filterQuery =
        Object.keys(state.filters).length > 0
          ? "filters=" + state.filters.join(",")
          : "";

      const fieldsQuery = Array.isArray(fields)
        ? "fields=" + fields.join(",")
        : "";

      const url =
        config.API_END_POINT +
        "contacts/export?export=1&" +
        (archive && limit && "archive=1&limit=" + limit) +
        (sortQuery ? "&" + sortQuery : "") +
        (filterQuery ? "&" + filterQuery : "") +
        (fieldsQuery ? "&" + fieldsQuery : "");
      try {
        requestHelper
          .request({
            url,
            method: "GET",
            token,
            notJson: true,
          })
          // download working code founded in https://gist.github.com/raymondpittman/11cc82788422d1bddfaa62e60e5ec9aa
          .then((responseData) => {
            return responseData.blob();
          })
          .then((stream) => {
            return stream;
          })
          .then((csvOrArchive) => {
            let fileExtension = archive ? ".zip" : ".csv";
            let elm = document.createElement("a"); // CREATE A LINK ELEMENT IN DOM
            elm.href = URL.createObjectURL(csvOrArchive); // SET LINK ELEMENTS CONTENTS
            elm.setAttribute("download", "export" + fileExtension); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD
            elm.click();
            commit("EXPORT_SUCCESS");
            commit("SET_NOTIFICATION_MESSAGE", {
              message: "Exportation au format " + fileExtension + " terminé",
            });
          });
      } catch (error) {
        console.error(error);
        commit("EXPORT_ERROR", { error });
      }
    },
    getContacts: ({ state, commit }, options) => {
      const { currentPage, itemsPerPage } = state.pager;
      const { token } = state.user;
      const { page, limit, sort, filter } = options;
      const pageFrom = page ? page : currentPage;
      const limitTo = limit ? limit : itemsPerPage;
      if (sort) {
        commit("ADD_SORT", {
          sort,
        });
      }
      const sortQuery =
        Object.keys(state.sorts).length > 0
          ? "sorts=" + state.sorts.join(",")
          : "";

      if (filter) {
        commit("ADD_FILTER", {
          filter,
        });
      }
      const filterQuery =
        Object.keys(state.filters).length > 0
          ? "filters=" + state.filters.join(",")
          : "";

      const url =
        config.API_END_POINT +
        "contacts?page=" +
        pageFrom +
        (sortQuery ? "&" + sortQuery : "") +
        (filterQuery ? "&" + filterQuery : "") +
        "&limit=" +
        limitTo;
      requestHelper
        .request({
          url,
          method: "GET",
          token,
        })
        .then((responseData) => {
          commit("SET_CONTACTS", { contacts: responseData.contacts });
          commit("SET_PAGER", {
            currentPage: pageFrom,
            itemsPerPage: limitTo,
            totalItems: responseData.count,
          });
        });
    },
    deleteContact: ({ state, commit, dispatch }, contactId) => {
      commit("DELETE_PENDING");
      const { token } = state.user;
      if (!contactId) {
        commit("DELETE_ERROR", { error: "no id provided !" });
        return;
      }
      const url = config.API_END_POINT + "contacts/" + contactId;
      requestHelper
        .request({
          url,
          method: "DELETE",
          token,
        })
        .then((responseData) => {
          if (responseData.error) {
            return commit("DELETE_ERROR", { error: responseData.error });
          }
          commit("DELETE_SUCCESS");
          const { message } = responseData;
          commit("SET_NOTIFICATION_MESSAGE", { message });
          dispatch("getContacts", {});
        });
    },
    saveContact({ state, commit, dispatch }, contact) {
      const { token } = state.user;
      commit("EDIT_PENDING");
      let method = "POST";
      let url = config.API_END_POINT + "contacts/";
      if (contact._id) {
        method = "PUT";
        url += contact._id;
      }
      requestHelper
        .request({
          url,
          method,
          token,
          body: { contact },
        })
        .then((responseData) => {
          if (responseData.error) {
            commit("EDIT_ERROR", { error: responseData.error });
          } else {
            const { message } = responseData;
            commit("EDIT_SUCCESS");
            commit("SET_NOTIFICATION_MESSAGE", { message });
            dispatch("getContacts", {});
          }
        });
    },
  },
  modules: {},
});
